.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}
.fs-38 {
  font-size: 38px;
}
.fs-40 {
  font-size: 40px;
}
.fs-50 {
  font-size: 50px;
}
.fs-70 {
  font-size: 70px;
}
/* font weight */

.fw-nm {
  font-weight: normal;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* padding */
.pd-l-0 {
  padding-left: 0px;
}
.pd-l-5 {
  padding-left: 5px;
}
.pd-r-10 {
  padding-right: 10px;
}
.pd-l-10 {
  padding-left: 10px;
}
.pd-l-25 {
  padding-left: 25px;
}
.pd-r-5 {
  padding-right: 5px;
}
.p-5 {
  padding: 5px;
}
.pd-0 {
  padding: 0px !important;
}
.pd-b-5 {
  padding-bottom: 5px !important;
}
.pd-b-10 {
  padding-bottom: 10px !important;
}
.pd-b-15 {
  padding-bottom: 15px !important;
}
.pd-b-20 {
  padding-bottom: 20px !important;
}
.pd-b-35 {
  padding-bottom: 35px !important;
}
.pd-t-5 {
  padding-top: 5px !important;
}
.pd-t-10 {
  padding-top: 10px !important;
}
.pd-t-15 {
  padding-top: 15px !important;
}
.pd-t-25 {
  padding-top: 25px !important;
}
.pd-t-30 {
  padding-top: 30px !important;
}

/* margin */
.mr-0 {
  margin: 0px !important;
}
.mr-t-5 {
  margin-top: 5px !important;
}
.mr-b-0 {
  margin-bottom: 0px !important;
}
.mr-b-5 {
  margin-bottom: 5px !important;
}
.mr-l-5 {
  margin-left: 5px !important;
}
.mr-b-10 {
  margin-bottom: 10px !important;
}

.mr-b-15 {
  margin-bottom: 15px !important;
}
.mr-b-20 {
  margin-bottom: 20px !important;
}
.mr-b-25 {
  margin-bottom: 25px !important;
}
.mr-b-35 {
  margin-bottom: 35px !important;
}
.mr-r-5 {
  margin-right: 5px !important;
}
.mr-r-10 {
  margin-right: 10px !important;
}
.mr-l-25 {
  margin-left: 25px !important;
}
.mr-r-0-18 {
  margin: 0px 18px;
}
.mr-t-10 {
  margin-top: 10px !important;
}
.mr-t-15 {
  margin-top: 15px !important;
}
.mr-t-12 {
  margin-top: 12px !important;
}
.mr-t-20 {
  margin-top: 20px !important;
}
.mr-t-25 {
  margin-top: 25px !important;
}
.mr-t-35 {
  margin-top: 35px !important;
}
.mr-l-10 {
  margin-left: 10px !important;
}
.mr-l-15 {
  margin-left: 15px !important;
}
/* display */
.absolute {
  position: absolute;
}
.block {
  display: block;
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}

.top-0 {
  top: 0;
}
.right-0{
    right: 0;
}
.d-flex {
  display: flex;
}
.flex-dir-col {
  flex-direction: column;
}
.flex-dir-col-rev {
  flex-direction: column-reverse;
}
.ds-none {
  display: none;
}
.flex-col {
  flex-direction: column;
}
.d-flex-cen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-start {
  display: flex;
  align-items: center;
  justify-content: start;
}
.d-flex-end {
  display: flex;
  align-items: center;
  justify-content: end;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-start {
  align-items: flex-start;
}

/* btn */
.h-40 {
  height: 40px;
}
.h-full {
  height: 100%;
}
.text-purple {
  color: #8030f8 !important;
}
.text-white {
  color: #fff;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.buttonClr {
  background: #6513e1;
  border-radius: 40px;
}
.grayBtnClr {
  background: #63676a;
}
.graytext {
  color: #63676a;
}
.customBtnStyle {
  --border-radius: 8px;
  --box-shadow: none !important;
  min-height: 48px;
  box-shadow: none !important;
}
.customBtnStyle span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: initial;
}
.onboarding-ion {
  --background: none;
  background: linear-gradient(
      180deg,
      rgb(255 255 255 / 85%) -1.46%,
      rgb(255 255 255 / 85%) 31.54%,
      rgb(255 255 255 / 97%) 60.54%,
      rgb(255 255 255 / 76%) 80%,
      rgb(255 255 255 / 19%) 92%,
      rgb(255 255 255 / 7%) 95%
    ),
    url("../../../src/assets/images/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  place-content: space-between;
}
.white-bg {
  background-color: #fff;
}
.radius-full {
  border-radius: 50%;
}
.feed-header .searchbar-input.sc-ion-searchbar-md {
  font-size: 14px;
}
.text-gray {
  color: #888e9d;
}
@media screen and (min-width: 992px) and (max-width: 3300px) {
  .onboarding-ion {
    --background: none;
    background: linear-gradient(
        180deg,
        rgb(255 255 255 / 50%) -1.46%,
        rgb(255 255 255 / 85%) 31.54%,
        rgb(255 255 255 / 97%) 60.54%,
        rgb(255 255 255 / 76%) 80%,
        rgb(255 255 255 / 19%) 92%,
        rgb(255 255 255 / 7%) 95%
      ),
      url("../../../src/assets/images/background-desktop.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    place-content: space-between;
  }
}
